.ContentListFull {
    padding: 0 20px;
}

.ContentListFull-ItemsWrapper {
    display: grid;
    margin: 0 auto;
    align-self: center;
    padding: 0 0px 40px 0px;
    grid-gap: 40px;
    grid-template-columns: repeat(4, minmax(240px, 350px));
    grid-auto-flow: dense;
}

.ContentListFull-Item_type_music {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

.swiper-scrollbar {
    margin: auto 0;
}

.CategoryPage .CategoryItem-MoreButton {
    display: none;
}

@media (max-width: 1200px) { 

    .ContentListFull-ItemsWrapper {
         grid-template-columns: repeat(2, minmax(240px, 350px));
         
    }
    
}

@media (max-width: 600px) {

    .ContentListFull-ItemsWrapper {
        grid-template-columns: repeat(1, minmax(240px, 280px));
        justify-content: center;
        padding: 0;
    }
}

