.CategoryPage {
}


.CategoryPage-Content {
    margin-top: 93px;
}

@media (max-width: 1200px) {
    .CategoryPage-Content {
        margin-top: 70px;
    }
}
