.ContentListPartial {
    display: flex;
    justify-content: center;
}

.ContentListPartial-ItemsWrapper {
    display: grid;
    flex-direction: column;
    justify-content: center;
    grid-gap: 40px;
    padding: 0 20px;
    width: 100%;
    grid-template-columns: repeat(4, minmax(200px, 320px));
    grid-template-rows: repeat(3, auto);

}

.ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

@media (max-width: 1200px) {
    .ContentListPartial-ItemsWrapper {
        grid-template-columns: repeat(2, minmax(200px, 348px));
    }

    .ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
        grid-column: span 1;
        grid-row: span 2;
        margin-bottom: 0;
        height: auto;
    }
}



@media (max-width: 600px) {
    .ContentListPartial-ItemsWrapper {
        grid-template-columns: repeat(1, auto);
        grid-template-rows: repeat(8, auto);
    }
}
