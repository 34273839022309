.ContentItemPreview {
    /* width: 320px; */
    min-width: 270px;
    height: 400px;
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
}


.ContentItemPreview-ImageWrap {
    z-index: 1;
    width: 85%;
    min-height: 280px;
    margin: 0 auto;
}

.ContentItemPreview-Link {
    height: 100%;
    padding: 20px 0 0 0;
}

.ContentItemPreview-Image {
    height: 280px;
    border-radius: 10px;
}

/*стили для size_small*/

.ContentItemPreview_size_small {
    height: 140px;
}

.ContentItemPreview_size_small .ContentItemPreview-Info {
    display: flex;
    overflow: hidden;
}

.ContentItemPreview_size_small .ContentItemPreview-ImageWrap {
    width: 100px;
    height: 100px;
    min-width: 100px;
    margin: auto;
    min-height: 100px;
    margin-right: 23px;
}

.ContentItemPreview_size_small .ContentItemPreview-Image {
    width: 110px;
    height: 100px;
    margin-left: -10px;
}

.ContentItemPreview_size_small .ContentItemPreview-Button {
    left: 120px;
    bottom: 20px;
    min-width: 140px;
}

.ContentItemPreview_size_small .ContentItemPreview-Cart {
    bottom: 15px;
}

.ContentItemPreview_size_small .ContentDescription-Tags {
    display: none;
}

.ContentItemPreview_size_small .ContentDescription-Title {
    color: #000;
    font-size: 12px;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 400;
    width: 80%;
}

.ContentItemPreview-Cart {
     position: absolute;
     margin: 10px;
     content: '';
     display: inline;
     width: 25px;
     height: 25px;
     background-color: #fff000;
     border-radius: 50%;
     background-image: url('../Icons/cart.svg');
     background-position: center;
     background-size: 60%;
     background-repeat: no-repeat;
     z-index: 5;
}



.ContentItemPreview-Button {
    position: absolute;
    width: 140px;
    height: 30px;
    bottom: 110px;
    left: 30px;
    z-index: 5;

    background: #fff000;
    border-radius: 6px;
}

.ContentItemPreview-ButtonText {
    position: absolute;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
    margin-left: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    color: #3B454E;
    content: '';
}
.ContentItemPreview-type-read:before {
    background-image: url('../Icons/moreRead.svg');
}

.ContentItemPreview-type-video:before {
    background-image: url('../Icons/moreVideo.svg');
}

.ContentItemPreview-ButtonText:before {
    fill: #fff;
    position: absolute;
    top: 14px;
    transform: translateY(-50%);
    left: 0;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

/*стили для странцы контента*/
.ContentItemPreview_size_common .ContentItemPreview-Info {
    display: flex;
    flex-direction: column;
}

.ContentItemPreview_size_common .ContentItemPreview-ImageWrap {
    order: 1;
}




@media (max-width: 800px) {

    .ContentItemPreview {
        height: 432px;
    }

    .ContentItemPreview-Image {
        height: 300px;
    }

    .ContentItemPreview-ImageWrap {
        min-height: 300px;
    }

    .ContentItemPreview-Button {
        bottom: 125px;
        left: 35px;
    }

    .ContentItemPreview_size_small {
        height: 168px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Cart {
        bottom: 24px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Button {
        bottom: 24px;
        left: 135px;
        width: 45%;
    }

    .ContentAdditional .ContentItemPreview_size_big {
        display: none;
    }

    .ContentItemPreview_size_small .ContentItemPreview-ImageWrap,
    .ContentItemPreview_size_small .ContentItemPreview-Image {
        width: 130px;
        height: 120px;
        min-width: 130px;
        margin-right: 5px;
    }

    .ContentItemPreview-Link {
        padding: 25px 0 0 0;
    }
}

@media (max-width: 600px) {

    .ContentItemPreview-Link {
        padding: 20px 0 0 0;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Image {
        width: 110px;
        height: 100px;
    }

    .ContentItemPreview {
        height: 360px;
    }

    .ContentItemPreview-Image {
        width: 240px;
        height: 240px;
    }

    .ContentItemPreview-ImageWrap {
        min-height: 240px;
        width: 240px;
    }

    .ContentItemPreview-Button {
        bottom: 115px;
        left: 35px;
    }

    .ContentItemPreview_size_small {
        min-width: 280px;
        width: 280px;
        height: 140px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Cart {
        bottom: 15px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Button {
        left: 120px;
        bottom: 21px;
    }

    .ContentAdditional .ContentItemPreview_size_big {
        display: none;
    }

    .ContentItemPreview_size_small .ContentItemPreview-ImageWrap,
    .ContentItemPreview_size_small .ContentItemPreview-Image {
        width: 110px;
        height: 100px;
        min-width: 100px;
        margin-right: 23px;

    }

}
