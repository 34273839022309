.Equalizer {
    width: 18px;
    height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Equalizer div {
    display: inline-block;
    background-color: #fff000;
    width: 4px;
    border-radius: 2px;
}

.Equalizer div:nth-child(1), .Equalizer div:nth-child(3) {
    background-color: #fff000;
    -webkit-animation: .75s linear 300ms infinite normal equalizerPlay2;
            animation: .75s linear 300ms infinite normal equalizerPlay2;
}

.Equalizer div:nth-child(2) {
    background-color: #fff000;
    -webkit-animation: .75s linear 300ms infinite normal equalizerPlay1;
            animation: .75s linear 300ms infinite normal equalizerPlay1;
}

.MusicItemPreview {
    height: 360px;
    min-width: 270px;
    max-width: 280px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
    padding: 20px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: visible;
    font-family: 'Roboto', sans-serif;
    background: #fff;
}

.MusicItemPreview-ImageWrapper {
    height: 240px;
    width: 100%;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
}

.MusicItemPreview-Image {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.MusicItemPreview-Progress {
    position: absolute;
    top: 0;
    background: #fff000;
    width: 130px;
    height: 2px;
}

.MusicItemPreview-TrackInfo {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.MusicItemPreview-TrackInfoWrapper {
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    border-radius: 0 0 10px 10px;
    width: 100%;
    height: 60px;
}

.MusicItemPreview-TrackInfoInner {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Roboto, sans-serif;
    overflow: hidden;
    position: relative;
    padding-left: 20px;
}

.MusicItemPreview-TrackInfoInner:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    background: -webkit-gradient(linear, right top, left top, color-stop(20%, rgba(255, 255, 255, 1)), color-stop(30%, rgba(255, 255, 255, 0.9)), color-stop(40%, rgba(255, 255, 255, 0.8)), color-stop(50%, rgba(255, 255, 255, 0.7)), color-stop(60%, rgba(255, 255, 255, 0.6)), color-stop(70%, rgba(255, 255, 255, 0.5)), color-stop(80%, rgba(255, 255, 255, 0.4)), to(rgba(255, 255, 255, 0.1)));
    background: -webkit-linear-gradient(right, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.8) 40%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0.4) 80%, rgba(255, 255, 255, 0.1) 100%);
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.8) 40%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0.4) 80%, rgba(255, 255, 255, 0.1) 100%);
    z-index: 1;
    opacity: 0.8;
}

/*.MusicItemPreview-TrackInfoInner:before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    width: 20px;*/
/*    background: linear-gradient(to right, rgba(255, 255, 255, 0.9) 10%, rgba(255, 255, 255, 0.8) 20%, rgba(255, 255, 255, 0.5) 40%, rgba(255, 255, 255, 0.4) 60%, rgba(255, 255, 255, 0.3) 70%, rgba(255, 255, 255, 0.2) 80%, rgba(255, 255, 255, 0.1) 100%);*/
/*    z-index: 1;*/
/*    opacity: 0.9;*/
/*}*/


.MusicItemPreview-PlayerState {
    min-width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background: rgba(255, 255, 255, 0.8);*/
}

.MusicItemPreview-TrackName {
    font-size: 14px;
    margin-bottom: 5px;
    width: 100%;
}

.MusicItemPreview-TrackNameInner,
.MusicItemPreview-PlayerNameInner,
.MusicItemPreview-NextTrackNameInner,
.MusicItemPreview-NextPlayerNameInner
{
    display: inline-block;
    white-space: nowrap;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.MusicItemPreview-TrackNameInner.MusicItemPreview-TrackNameInner_isTicker,
.MusicItemPreview-PlayerNameInner.MusicItemPreview-PlayerNameInner_isTicker,
.MusicItemPreview-NextPlayerNameInner.MusicItemPreview-NextPlayerNameInner_isTicker,
.MusicItemPreview-NextTrackNameInner.MusicItemPreview-NextTrackNameInner_isTicker {
    -webkit-animation: ticker 5s infinite ease-in-out;
            animation: ticker 5s infinite ease-in-out;
}

.MusicItemPreview-PlayerName {
    color: #414141;
    font-size: 12px;
    width: 100%;
}

.MusicItemPreview-NextTrackWrapper {
    margin-top: 17px;
    cursor: pointer;
    min-height: 60px;
}

.MusicItemPreview-NextTrackTitle {
    font-size: 12px;
    line-height: 1;
    color: #8D8D8D;
    margin: 0;
    font-family: 'Roboto Slab', sans-serif;
}

.MusicItemPreview-NextTrackInfo {
    padding-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    flex: 1 1;
}

.MusicItemPreview-NextTrackInfoInner:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    background: -webkit-gradient(linear, right top, left top, color-stop(60%, rgba(255, 255, 255, 1)), color-stop(70%, rgba(255, 255, 255, 0.8)), color-stop(80%, rgba(255, 255, 255, 0.6)), color-stop(90%, rgba(255, 255, 255, 0.2)), to(rgba(255, 255, 255, 0.1)));
    background: -webkit-linear-gradient(right, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.6) 80%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0.1) 100%);
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.6) 80%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0.1) 100%);
    opacity: 0.9;
}

.MusicItemPreview-NextTrackInfoWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.MusicItemPreview-NextTrackInfoInner {
    display: flex;
    padding-right: 5px;
    width: 100%;
}

.MusicItemPreview-NextTrackInfoInner.MusicItemPreview-NextTrackInfoInner_hidden {
    display: none;
}

.MusicItemPreview-NextTrackInfoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    flex: 1 1;
    overflow: hidden;
    width: 100%;
}

.MusicItemPreview-Arrow {
    z-index: 1;
    position: absolute;
    right: 0;
}

.MusicItemPreview-NextTrackPreviewWrapper {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background-color: grey;
    position: relative;
}

.MusicItemPreview-NextTrackPreviewWrapper::before {
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    background-image: url(/static/media/nextTrackPlay.0f35356f.svg);
    background-position: 50% 50%;
    background-size: cover;
    height: 19px;
    width: 19px;
}

.MusicItemPreview-NextTrackName {
    font-size: 12px;
    margin-bottom: 3px;
}

.MusicItemPreview-NextPlayerName {
    font-size: 10px;
    color: #717171;
}

@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-120%, 0);
                transform: translate(-120%, 0);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-120%, 0);
                transform: translate(-120%, 0);
    }
}

@media (min-width: 601px) {
    .MusicItemPreview {
        max-width: unset;
        width: 100%;
        height: 432px;
    }
    .MusicItemPreview-ImageWrapper {
        height: 304px;
    }
}

@media (min-width: 1200px) {
    .MusicItemPreview {
        height: 400px;
    }
    .MusicItemPreview-ImageWrapper {
        height: 280px;
    }
}

.ContentDescription {
    position: relative;
    font-family: Roboto, sans-serif;
    color: #333333;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 85%;
    max-width: 280px;
    margin: auto;
    margin-top: 10px;
}

.ContentDescription_size_small {
    color: #58595b;
    margin: 0;
    margin-top: -2px;
}

.ContentDescription-Title {
    background-color: #fff;
    color: #8A8D8F;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
    position: relative;
    font-family: "Roboto", sans-serif;
}

.ContentDescription-Tags,
.ContentDescription-Type {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 700;
    height: 20px;
    margin-left: 0;
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
    color: #000;
}

.ContentDescription-TitleText {
    font-family: 'Roboto', sans-serif;
}

.ContentDescription-Type {
    display: none;
}

/*стили для контента на странице контента*/
.ContentDescription_ofPage_ContentPage.ContentDescription_size_common .ContentDescription-Title{
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
}


@media (max-width: 600px) {
    .ContentDescription {
        width: 240px;
    }
}
.ContentItemPreview {
    /* width: 320px; */
    min-width: 270px;
    height: 400px;
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
}


.ContentItemPreview-ImageWrap {
    z-index: 1;
    width: 85%;
    min-height: 280px;
    margin: 0 auto;
}

.ContentItemPreview-Link {
    height: 100%;
    padding: 20px 0 0 0;
}

.ContentItemPreview-Image {
    height: 280px;
    border-radius: 10px;
}

/*стили для size_small*/

.ContentItemPreview_size_small {
    height: 140px;
}

.ContentItemPreview_size_small .ContentItemPreview-Info {
    display: flex;
    overflow: hidden;
}

.ContentItemPreview_size_small .ContentItemPreview-ImageWrap {
    width: 100px;
    height: 100px;
    min-width: 100px;
    margin: auto;
    min-height: 100px;
    margin-right: 23px;
}

.ContentItemPreview_size_small .ContentItemPreview-Image {
    width: 110px;
    height: 100px;
    margin-left: -10px;
}

.ContentItemPreview_size_small .ContentItemPreview-Button {
    left: 120px;
    bottom: 20px;
    min-width: 140px;
}

.ContentItemPreview_size_small .ContentItemPreview-Cart {
    bottom: 15px;
}

.ContentItemPreview_size_small .ContentDescription-Tags {
    display: none;
}

.ContentItemPreview_size_small .ContentDescription-Title {
    color: #000;
    font-size: 12px;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 400;
    width: 80%;
}

.ContentItemPreview-Cart {
     position: absolute;
     margin: 10px;
     content: '';
     display: inline;
     width: 25px;
     height: 25px;
     background-color: #fff000;
     border-radius: 50%;
     background-image: url(/static/media/cart.05e7040f.svg);
     background-position: center;
     background-size: 60%;
     background-repeat: no-repeat;
     z-index: 5;
}



.ContentItemPreview-Button {
    position: absolute;
    width: 140px;
    height: 30px;
    bottom: 110px;
    left: 30px;
    z-index: 5;

    background: #fff000;
    border-radius: 6px;
}

.ContentItemPreview-ButtonText {
    position: absolute;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
    margin-left: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    color: #3B454E;
    content: '';
}
.ContentItemPreview-type-read:before {
    background-image: url(/static/media/moreRead.b05e0a3d.svg);
}

.ContentItemPreview-type-video:before {
    background-image: url(/static/media/moreVideo.ae0f85e7.svg);
}

.ContentItemPreview-ButtonText:before {
    fill: #fff;
    position: absolute;
    top: 14px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 0;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

/*стили для странцы контента*/
.ContentItemPreview_size_common .ContentItemPreview-Info {
    display: flex;
    flex-direction: column;
}

.ContentItemPreview_size_common .ContentItemPreview-ImageWrap {
    order: 1;
}




@media (max-width: 800px) {

    .ContentItemPreview {
        height: 432px;
    }

    .ContentItemPreview-Image {
        height: 300px;
    }

    .ContentItemPreview-ImageWrap {
        min-height: 300px;
    }

    .ContentItemPreview-Button {
        bottom: 125px;
        left: 35px;
    }

    .ContentItemPreview_size_small {
        height: 168px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Cart {
        bottom: 24px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Button {
        bottom: 24px;
        left: 135px;
        width: 45%;
    }

    .ContentAdditional .ContentItemPreview_size_big {
        display: none;
    }

    .ContentItemPreview_size_small .ContentItemPreview-ImageWrap,
    .ContentItemPreview_size_small .ContentItemPreview-Image {
        width: 130px;
        height: 120px;
        min-width: 130px;
        margin-right: 5px;
    }

    .ContentItemPreview-Link {
        padding: 25px 0 0 0;
    }
}

@media (max-width: 600px) {

    .ContentItemPreview-Link {
        padding: 20px 0 0 0;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Image {
        width: 110px;
        height: 100px;
    }

    .ContentItemPreview {
        height: 360px;
    }

    .ContentItemPreview-Image {
        width: 240px;
        height: 240px;
    }

    .ContentItemPreview-ImageWrap {
        min-height: 240px;
        width: 240px;
    }

    .ContentItemPreview-Button {
        bottom: 115px;
        left: 35px;
    }

    .ContentItemPreview_size_small {
        min-width: 280px;
        width: 280px;
        height: 140px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Cart {
        bottom: 15px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Button {
        left: 120px;
        bottom: 21px;
    }

    .ContentAdditional .ContentItemPreview_size_big {
        display: none;
    }

    .ContentItemPreview_size_small .ContentItemPreview-ImageWrap,
    .ContentItemPreview_size_small .ContentItemPreview-Image {
        width: 110px;
        height: 100px;
        min-width: 100px;
        margin-right: 23px;

    }

}

.ContentListFull {
    padding: 0 20px;
}

.ContentListFull-ItemsWrapper {
    display: grid;
    margin: 0 auto;
    align-self: center;
    padding: 0 0px 40px 0px;
    grid-gap: 40px;
    grid-template-columns: repeat(4, minmax(240px, 350px));
    grid-auto-flow: dense;
}

.ContentListFull-Item_type_music {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

.swiper-scrollbar {
    margin: auto 0;
}

.CategoryPage .CategoryItem-MoreButton {
    display: none;
}

@media (max-width: 1200px) { 

    .ContentListFull-ItemsWrapper {
         grid-template-columns: repeat(2, minmax(240px, 350px));
         
    }
    
}

@media (max-width: 600px) {

    .ContentListFull-ItemsWrapper {
        grid-template-columns: repeat(1, minmax(240px, 280px));
        justify-content: center;
        padding: 0;
    }
}


.ContentListPartial {
    display: flex;
    justify-content: center;
}

.ContentListPartial-ItemsWrapper {
    display: grid;
    flex-direction: column;
    justify-content: center;
    grid-gap: 40px;
    padding: 0 20px;
    width: 100%;
    grid-template-columns: repeat(4, minmax(200px, 320px));
    grid-template-rows: repeat(3, auto);

}

.ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

@media (max-width: 1200px) {
    .ContentListPartial-ItemsWrapper {
        grid-template-columns: repeat(2, minmax(200px, 348px));
    }

    .ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
        grid-column: span 1;
        grid-row: span 2;
        margin-bottom: 0;
        height: auto;
    }
}



@media (max-width: 600px) {
    .ContentListPartial-ItemsWrapper {
        grid-template-columns: repeat(1, auto);
        grid-template-rows: repeat(8, auto);
    }
}

.PressContentItem {
    text-decoration: none;
    color: inherit;
    max-height: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PressContentItem-ImageWrap {
    width: 200px;
    height: 262px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);

}

.swiper-container {
    max-width: 1600px;
}

.PressContentItem-Image {
    position: absolute;
    display: block;
    width: 200px;
    height: 262px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

}

.PressContentItem .PressContentItem-ImageWrap:before {
    position: absolute;
    content: '';
    display: inline;
    right: 15px;
    bottom: 10px;
    width: 25px;
    height: 25px;

    background-color: #fff000;
    border-radius: 50%;
    background-image: url(/static/media/cart.05e7040f.svg);
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
    z-index: 1;
}

.PressContentItem-Title {
    text-align: center;
    position: relative;
    font-family: "Lato Regular", Arial, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 100;
    color: #ffffff;
}

@media (min-width: 500px) {
    .PressContentItem_premium .PressContentItem-ImageWrap:before {
        width: 40px;
        height: 40px;
    }

}

@media (max-width: 800px) {
    .PressContentItem {
        /* width: 144px !important; */
        /* margin-right: 10px !important; */
    }

    .swiper-container {
        /* width: 100%; */
    }

    .PressContentItem-Image,
    .PressContentItem-ImageWrap {
        width: 144px;
        height: 188px;
    }
}

@media (max-width: 600px) {

    .PressContentItem {
        /* width: 130px !important; */
    }

    .PressContentItem-Image,
    .PressContentItem-ImageWrap {
        width: 130px;
        height: 170px;
    }
}


@media (min-width: 1200px) {

    .PressContentItem-ImageWrap {
        height: 262px;
        width: 200px;
    }

    .PressContentItem .PressContentItem-ImageWrap:before {
        width: 35px;
        height: 35px;
    }
}


.PressContentSlider-ContentItem {

}

.PressContentSlider .swiper-button-prev,
.PressContentSlider .swiper-button-next {
    display: none;
    background-color: #fff;
    background-size: 50%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    z-index: 100000000;
}
.PressContentSlider .swiper-button-next.swiper-button-disabled,
.PressContentSlider .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
}

.PressContentSlider .swiper-button-prev::before,
.PressContentSlider .swiper-button-next::before {
    display: block;
    content: '';
    width: 500px;
    height: 350px;
    background: -webkit-gradient(linear, left top, right top, from(#3B454E), color-stop(70%, rgba(59, 69, 78, 0)));
    background: -webkit-linear-gradient(left, #3B454E 0%, rgba(59, 69, 78, 0) 70%);
    background: linear-gradient(90deg, #3B454E 0%, rgba(59, 69, 78, 0) 70%);
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(-1px);
            transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(-1px);
    pointer-events: none;
    position: absolute;
    bottom: -130px;
    right: -100px;
}

.PressContentSlider-blackout-next {
     -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
             transform: matrix(1, 0, 0, 1, 0, 0);
     right: 20px;
}

.swiper-scrollbar-drag {
    height: 4px;
    background: #ADAFAF;
}

.swiper-scrollbar {
    margin: auto;
}

/*.swiper-slide {*/
/*    width: auto !important;*/
/*    margin-right: 10px !important;*/
/*}*/

.PressContentPage .swiper-slide {
    width: 100% !important;
    margin-right: 30px !important;
}

@media (min-width: 1200px) {
    .swiper-button-prev::before,
    .swiper-button-next::before {
        display: block;
    }

    .PressContentSlider .swiper-button-prev,
    .PressContentSlider .swiper-button-next {
        display: block;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;

    }
    .CategoryPage-Content .swiper-button-prev {
        background: transparent;
    }
    .CategoryPage-Content .swiper-button-prev::before,
    .CategoryPage-Content .swiper-button-next::before {
        height: 320px;
        bottom: -130px;
        background: -webkit-gradient(linear, left top, right top, from(#3B454E), to(rgba(59, 69, 78, 0)));
        background: -webkit-linear-gradient(left, #3B454E 0%, rgba(59, 69, 78, 0) 100%);
        background: linear-gradient(90deg, #3B454E 0%, rgba(59, 69, 78, 0) 100%);
        -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
                transform: matrix(-1, 0, 0, 1, 0, 0);
        -webkit-transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(-1px);
                transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(-1px);

    }

}



.PressContentList-Item {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 20px 10px 0 rgb(0 0 0 / 20%);
    margin-bottom: 10px;
}

.PressContentList-Wrapper {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.PressContentList-ItemTitle {
    
    max-width: calc(100% - 40px);
    display: inline-block;
    font-size: 26px;
    line-height: 1;
    padding: 0;
    margin: 0;
    text-align: start;
    font-family: "Lato Light", Arial, sans-serif;
    /*max-width: 745px;*/
    color: #fff;
    margin: 15px 20px;
}

.PressContentList-ItemTitle span {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 56px;
    padding-bottom: 10px;
}

@media (max-width: 600px) {}


@media (max-width: 800px) {
    
    .PressContentList-Wrapper {
        width: 100%;
    }
}


.CategoryItem {
    display: flex;
    justify-content: center;
}

.CategoryItem-Wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1600px;
}

.CategoryItem_theme_dark .CategoryItem-Wrapper {
     max-width: 100%;
     padding: 0;
}

.MainPage-Content .CategoryItem_theme_dark .CategoryItem-Wrapper {
    height: 530px;
}

.CategoryItem-ContentWrap {
    /* width: 100%; */
    max-width: 1440px;
    margin: auto;
}

.CategoryItem-HomeLink_ofPage_main {
    display: none;
}

.CategoryItem-Title {
    position: relative;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #000;
    margin-left: 26px;
}

.CategoryItem-type_pressa{
    color: #fff;
}

/*.CategoryItem-type_pressa:before {*/
    /*background-image: url('../Icons/magazinesWhite.svg');*/
/*}*/

/*.CategoryItem-type_pro-sport:before {*/
    /*background-image: url('../Icons/sport.svg');*/
/*}*/

/*.CategoryItem-type_pro-avtomobili:before {*/
    /*background-image: url('../Icons/auto.svg');*/
/*}*/

/*.CategoryItem-type_pro-stil:before {*/
    /*background-image: url('../Icons/style.svg');*/
/*}*/

/*.CategoryItem-type_pro-gadzhety:before {*/
    /*background-image: url('../Icons/gadgets.svg');*/
/*}*/

/*.CategoryItem-type_pro-otdykh:before {*/
    /*background-image: url('../Icons/relaxation.svg');*/
/*}*/

/*.CategoryItem-type_pro-kosmos:before {*/
    /*background-image: url('../Icons/space.svg');*/
/*}*/

/*.CategoryItem-type_cookery:before {*/
    /*background-image: url('../Icons/food.svg');*/
/*}*/

/*.CategoryItem-type_music:before {*/
    /*background-image: url('../Icons/music.svg');*/
/*}*/


.CategoryItem-Title:before {
    fill: #fff;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: -30px;
    content: '';
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.CategoryPage .CategoryItem-Title::before {
    display: none;
}

/*разные фоны категорий */

.CategoryItem_theme_dark,
.CategoryItem:first-child {
    padding: 0;
    background: #3B454E;
}

.CategoryItem {
    background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#F7F7F7));
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #F7F7F7 100%);
    background: linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%);
 }

.CategoryItem_theme_light{
    background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#F7F7F7)) !important;
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #F7F7F7 100%) !important;
    background: linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%) !important;
}

.CategoryItem-TitleLink {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
    font-family: "Arial Bold" , sans-serif;
}

/*кнопка больше*/

.CategoryItem-MoreButtonLink {
    display: block;
    text-align: center;
    text-decoration: none;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: inherit;
    position: relative;
}

.CategoryItem_theme_light .CategoryItem-MoreButtonLink {
    margin-bottom: 30px;
}


.CategoryItem-MoreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 40px;
    margin: 0 auto;
    border-radius: 6px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
}

.CategoryItem .CategoryItem-MoreButton {
    background-color: #000;
    color: #fff;
}

.CategoryItem:first-child .CategoryItem-MoreButton {
    background-color: #fff000;
    color: #3B454E;
}

/*категория на странице категорий */
.CategoryItem_ofPage_category {
    background-color: #f6f6f6;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark {
    background-color: #3B454E;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S {
    /*filter: invert(100%);*/
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-Title {
    color: #ffffff;
}

.CategoryItem-MoreButtonWrapper{
    margin: auto;
}

.CategoryItem-LinkWrap {
    width: 100%;
    max-width: 1440px;
    align-self: center;
    position: relative;
    padding: 0 20px;
}

/*кнопка домой*/
.CategoryItem-HomeLink_ofPage_category {
    text-decoration: none;
    display: block;
    align-self: center;
    z-index: 1;
}

.CategoryItem-HomeLink-Btn_size_S {
    height: 30px;
    width: 30px;
    background-image: url(/static/media/backBlack.6375d4c1.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    position: relative;
}

.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S:nth-child(1) {
    background-image: url(/static/media/back.12097767.svg);
}

.MainPage-Content .CategoryItem-CategoryLink {
    height: 30px;
    width: 30px;
    background-image: url(/static/media/arrowMainPage.920cd8c7.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 25px;
    margin-left: 10px;
    margin: auto 0;

}

.CategoryItem:first-child .CategoryItem-CategoryLink {
    display: none;
}


@media (max-width: 1200px) {
    .CategoryPage-Content .CategoryItem-LinkWrap {
        display: flex;
    }
}

@media (max-width: 800px) {

    .MainPage-Content .CategoryItem_theme_dark .CategoryItem-Wrapper {
        height: 468px;
        width: 100%;
    }

    .CategoryItem-ContentWrap {
        width: 100%;
        margin-bottom: 30px;
    }

    .CategoryItem-LinkWrap {
        width: 100%;
    }

    .CategoryPage .CategoryItem-LinkWrap {
        padding: 0;
    }

    .CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-Title {
        margin-left: 4px;
    }



    .CategoryItem-HomeLink_ofPage_category {
        margin-left: 12px;
    }

    .CategoryPage .CategoryItem-MoreButtonWrapper {
        display: none;
    }

    .CategoryItem-MoreButton {
        width: 240px;
        height: 60px;
        font-size: 18px;
    }

}

@media (max-width: 600px) {

    .CategoryItem-MoreButton {
        width: 200px;
        height: 40px;
        font-size: 14px;
    }

    .MainPage-Content .CategoryItem_theme_dark .CategoryItem-Wrapper {
        height: 400px;
    }

}

@media (max-width: 1200px) {

    .CategoryItem_theme_light .CategoryItem-MoreButtonWrapper {
        margin-top: 20px;
    }

}

@media (min-width: 720px) {



    .CategoryItem_theme_dark .CategoryItem-MoreButtonLink {
        bottom: 0;
    }




    .CategoryItem-HomeLink-Btn_size_L {
        display: block;
        text-transform: lowercase;
        font-family: "Arial Narrow Bold", Arial, sans-serif;
        font-size: 20px;
    }


}

@media (min-width: 1200px) {



    .MainPage-Content .CategoryItem-CategoryLink {
        display: none;
    }

    .CategoryPage .CategoryItem-Title::before {
        display: block;

    }

    .CategoryItem-HomeLink.CategoryItem-HomeLink_ofPage_category {
        display: none;
    }

    .CategoryItem-Title:before {
        display: block;
        top: 50%;
    }

    .CategoryItem-HomeLink-Btn_size_S {
        display: none;
    }

    .CategoryItem-MoreButton {
        max-width: 180px;
        height: 40px;
        font-size: 14px;
    }

}

.CloseButton {
    cursor: pointer;
}
.HamburgerButton {
    cursor: pointer;
}
.Menu {
    min-width: 240px;
    font-family: "Arial Narrow", Arial, sans-serif;
    font-size: 22px;
    width: 100%;
    padding-top: 47px;
}

.Menu-Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.Menu_visible {
    display: block;
}

.Menu-List {
    width: 50%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 20px 0 20px 20px;
    background-color: #fff;
}

.Menu-Link,
.Menu-Link:hover,
.Menu-Link:active {
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 45px;
    height: 60px;
    line-height: 60px;
    color: currentColor;
    position: relative;
}

.Menu-Item svg {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 25px;
    width: 25px;
    display: block;
}

.Menu-Item path {
    fill: currentColor;
}

.Menu-Item,
.Menu-Item:hover,
.Menu-Item:active {
    position: relative;

}

.Menu-Item_type_pressa:before {
    background-image: url(/static/media/magazines.0ee56a32.svg);
}

.Menu-Item_type_sport:before {
    background-image: url(/static/media/sport.635e55a2.svg);
}

.Menu-Item_type_avto:before {
    background-image: url(/static/media/auto.25f0ca0d.svg);
}

.Menu-Item_type_stil:before {
    background-image: url(/static/media/style.4442bbd9.svg);
}

.Menu-Item_type_gadzheti:before {
    background-image: url(/static/media/gadgets.13285dfe.svg);
}

.Menu-Item_type_otdikh:before {
    background-image: url(/static/media/relaxation.d48b4557.svg);
}

.Menu-Item_type_kosmos:before {
    background-image: url(/static/media/space.f75a298a.svg);
}

.Menu-Item_type_pitanie:before {
    background-image: url(/static/media/food.3f0f78ff.svg);
}

/*.Menu-Item:before {*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    /*left: 0;*/
    /*content: '';*/
    /*display: block;*/
    /*width: 25px;*/
    /*height: 25px;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: contain;*/
/*}*/

.Menu-Item:hover,
.Menu-Item:active {
    color: #fff000;
}

@media (max-width: 600px) {
    .Menu-List {
        width: 70%;
    }
}


@media (min-width: 1200px) {
    .Menu {
        width: 100%;
        height: 60px;
        padding-top: 0;
        margin-left: 10%;
        margin-right: 10%;
        color: #000;
        font-weight: 500;
        font-size: 12px;
        font-family: "Roboto Slab Regular";
        text-transform: uppercase;
    }

    .Menu-Wrapper {
        width: 900px;
        flex-direction: row;
        background-color: transparent;
    }

    .Menu-List {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0;
        padding: 0;
        height: 70px;

        background-color: transparent;
    }

    .Menu-Item {
        flex-grow: 1;
        padding: 0 0 0 15px;
    }

    .Menu-Item:before {
        display: none;
    }

    .Menu-Link,
    .Menu-Link:hover,
    .Menu-Link:active {
        height: 70px;
        line-height: 70px;
        padding: 10px 0 0 0;
    }

    .Menu-ItemLink-Text {
        font-family: "Roboto-Bold", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-transform: none;
        line-height: 50px;
        text-align: center;
    }

    .Menu-Link:hover .Menu-ItemLink-Text,
    .Menu-Link:active .Menu-ItemLink-Text {
        border-bottom: 5px solid #fff000;
    }
}

.Header {
    background-color: #fff;
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 93px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Header-Wrapper {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    max-width: 1440px;
}

.Header-Logo {
    max-height: 25px;
}

.Header-LogoContainer {
    margin-right: 20px;
}

.Header-ButtonWrapper {
    position: relative;
    height: 100%;
    width: 50px;
    min-width: 50px;
}

.Header-Select {
    cursor: pointer;
    position: relative;
    margin-left: auto;
    width: 50px;
}

.Header-SelectList {
   display: none;
    padding: 0;
    list-style-type: none;
}

.Header-SelectList_visible {
    position: absolute;
    top: 100%;
    display: block;
    margin: 0;
    background-color: #fff;
    width: 40px;
}

.Header-SelectItem {
    font-family: "Roboto Slab Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 30px 10px 0;
    width: 60px;
}

.Header-SelectItem span {
    font-size: 16px;
}

.Header-SelectItem_state_selected {
    background-image: url(/static/media/down-arrow.2cad92c1.svg);
    background-repeat: no-repeat;
    background-position: 30px 13px;
    background-size: 10px;
}

.Header-SelectItem_active {
    display: none;
}


.Header-HamburgerButton,
.Header-CloseButton {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0;
    -webkit-transition:  all cubic-bezier(0.17,0.67,1,-0.1) 0.5s;
    transition:  all cubic-bezier(0.17,0.67,1,-0.1) 0.5s;
}

.Header-HamburgerButton_visible,
.Header-CloseButton_visible {
    opacity: 1;
}

.Header-Menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    height: 100vh;
    min-height: 100%;
    z-index: 1;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
    -webkit-transition: opacity ease-in-out 0.8s, -webkit-transform ease-in-out 0.2s;
    transition: opacity ease-in-out 0.8s, -webkit-transform ease-in-out 0.2s;
    transition: opacity ease-in-out 0.8s, transform ease-in-out 0.2s;
    transition: opacity ease-in-out 0.8s, transform ease-in-out 0.2s, -webkit-transform ease-in-out 0.2s;
}

.Header-Menu_visible {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    height: 200vh;
    width: 100%;
}



@media (max-width: 1200px) {
    .Header-Wrapper {
        width: 100%;
        padding: 10px 0;
    }
    .Header {
        height: 70px;
    }
}





@media (min-width: 1200px) {



    .Header-Menu,
    .Header-Menu_visible {
        display: flex;
        justify-content: center;
        position: relative;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        height: auto;
        top: auto;
        bottom: auto;
        left: 0px;
        opacity: 1;
    }

    .Header-ButtonWrapper {
        display: none;
    }

    .Header-LogoContainer {
        margin-left: 20px;
    }
}

.Header-PersonalCabinet {
    width: 100%;
    max-width: 1440px;
    font-size: 12px;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px #4c4c4e solid;
}

.Header-PersonalCabinet a {
    color: #4c4c4e;
    text-decoration: underline;
}

.CategoryPage {
}


.CategoryPage-Content {
    margin-top: 93px;
}

@media (max-width: 1200px) {
    .CategoryPage-Content {
        margin-top: 70px;
    }
}

.ContentAdditional {
    display: flex;
    flex-direction: column;
    margin: auto;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(8, auto);    
    margin-bottom: 30px;
}




@media (max-width: 800px) {

    .ContentAdditional {
        color: #fff;
        grid-template-columns: repeat(2, minmax(240px, 348px));
        grid-template-rows: repeat(2, auto);
        grid-auto-flow: dense;
    }

}

@media (max-width: 600px) {

    .ContentAdditional {
        grid-template-columns: repeat(1, auto);
        grid-template-rows: repeat(4, auto);
    }

}
.ContentText {
    text-align: justify;
    font-size: 14px;
    line-height: 20px;
    font-family: "Roboto", Arial, sans-serif;
    color: #000000;
    margin-bottom: 20px;
}

.ContentText p,
.ContentText ul {
    font-family: "Roboto", Arial, sans-serif;
}

.ContentText p:first-child {
    font-family: "Roboto", "Arial Narrow", sans-serif;
}

.ContentText h2, h3 {
    font-family: "Roboto", "Arial Bold", sans-serif;
    font-size: 24px;
    line-height: 30px;
    margin-left: 20px;
}

.ContentText h3 {
    font-size: 20px;
}

.ContentText h1,
.ContentText h2,
.ContentText h3,
.ContentText h4,
.ContentText h5 {
    text-align: left;
}

.ContentText li {
    list-style: none;
    position: relative;
    padding-left: 20px;
    padding-bottom: 10px;
    text-align: left;
}

.ContentText li:before {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    top: 10px;
    left: -5px;
    border-radius: 3px;
    background-color: #f5d300;
}

.ContentText p,
.ContentText i {
    text-indent: 20px;
    margin-bottom: 1em;
    display: block;
}

.ContentText img,
.ContentText video {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    width: 100%;
}

.ContentText p img {
    margin-bottom: 20px;
}

.ContentText video {
    margin-bottom: 20px;
}


@media (min-width: 720px) {
    .ContentText {
        font-size: 20px;
        line-height: 30px;
        font-family: 'Roboto', sans-serif;
        margin-bottom: 40px;
    }

    .ContentText p,
    .ContentText ul {
        font-family: inherit;
    }
}
.ErrorPage {
    text-align: center;
    height: 85vh;
    margin-bottom: 1px;
}

.ErrorPage-LogoContainer {
    background-color: #fff;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    align-items: center;
}

.ErrorPage-LogoContainer {
    padding-left: 20px;
}

.ErrorPage-Logo {
    max-height: 40px;
}


.ErrorPage-Title {
    margin: 30vh 0 20px;
    text-align: center;
}

.ErrorPage-Title span {
    display: block;
}

.ErrorPage-Link,
.ErrorPage-Link:hover,
.ErrorPage-Link_active {
    display: block;
    text-decoration: none;
    width: 170px;
    font-size: 24px;
    line-height: 50px;
    background-color: #f5d300;
    background-image: none;
    border-radius: 25px;
    text-align: center;
    margin: 0 auto 100px;
    color: #4c4c4e;
}

@media (min-width: 720px) {
    .ErrorPage {
        font-family: "Arial Narrow", Arial, sans-serif;
    }

    .ErrorPage * {
        font-family: inherit;
    }

}

.preloader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 50px;
    z-index: 10000;
    box-sizing: border-box;
}

.preloader--slider-item {
    padding-top: 43vh;
}

.preloader--fullscreen {
    position: fixed;
    padding: 0;
    background-color: #ffffff;
}

.preloader--vertical {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.ContentPage {
    display: flex;
    justify-content: center;
    padding: 0 20px;
}

.ContentPage-Content {
    margin-top: 93px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
}

@media (max-width: 1200px) {
    .ContentPage-Content {
        margin-top: 70px;
    }
}

.ContentPage-Wrapper {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
}

.ContentPage-CategoryLink-Btn {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
}

.ContentPage-CategoryLink-Btn_size_L {
    display: none;
}

.ContentPage-CategoryLink-Btn_size_S {
    background-image: url(/static/media/back.12097767.svg);
}

.ContentPage-AdditionalBar {
}

.ContentPage-Video {
    max-width: 100%;
    margin-bottom: 20px;
}

.ContentPage-Video[poster] {
    object-fit: cover;
}

.ContentPage-ContentAdditionalWrapper {
    display: flex;
    flex-direction: column;
}

.ContentPage-ContentAdditionalTitle {
    font-family: "Roboto Slab", sans-serif;
    font-weight: 700;
    color: #000;
    text-align: center;
    margin: 30px 0;
}

.ContentPage-Title {
    position: relative;
    margin-left: 11px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #000;
    display: flex;
    flex-direction: row;
}

.ContentPage-Image {
    float: none;
    width: 32vh !important;
    height: 35vh !important;
    margin-bottom: 10px;
}

.ContentPage-ContentName {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.2;
    color: #000;
    margin-bottom: 20px;
}

.ContentPage-CategoryLink,
.ContentPage-CategoryLink:hover,
.ContentPage-CategoryLink:active {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    z-index: 1;
    align-self: center;
    margin-right: 26px;
    background-image: url(/static/media/backBlack.6375d4c1.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
}

.ContentPage-Title:before {
    display: none;
}

.ContentPage-MainBar {
    width: 100%;
}

.ContentPage-ContentWrap{
    /* box-shadow: 0px 5px 15px rgb(0 0 0 / 25%); */
    border-radius: 10px;
    padding: 15px 40px 15px 15px;
    margin-top: -10px;
    box-shadow: none;
}


@media (max-width: 1200px) {

    .ContentPage-Image {
        width: 100% !important;

    }

    .ContentPage-Title {
        margin-left: 0;
    }

}

@media (max-width: 800px) {
    .ContentPage-Wrapper {
        width: 100%;
        max-width: 768px;
        flex-direction: column;
    }

    .ContentPage-Title {
        margin-left: -8px;
    }

    .ContentPage-ContentWrap {
        margin: 22px;
        padding: 20px;
        box-shadow: 0 5px 15px rgb(0 0 0 / 25%);
        width: auto;
    }

    .ContentPage-Image {
        width: 98% !important;
        align-items: center;
        margin: auto;
        margin-bottom: 20px;
        border-radius: 10px !important;
    }

    .ContentPage-AdditionalBar {
        margin-top: 0;
        max-width: 100%;
        justify-content: center;
        display: flex;
    }


}

@media (max-width: 400px) {

    .ContentPage {
        padding: 0;
    }

    .ContentPage-Wrapper {
        width: 100%;

    }

    .ContentPage-ContentWrap {
        margin: 0;
        padding: 20px 20px 0 20px;
    }

    .ContentPage-Image {
        width: 280px !important;
        height: 280px !important;
        border-radius: 10px !important;
    }

    .ContentPage-Title {
        margin-left: 12px;
    }

    .ContentPage-ContentAdditionalTitle {
        display: block;
    }
}


@media (min-width: 720px) {

    .ContentPage-ContentName {
        font-size: 32px;
    }

    .ContentPage-Video {
        width: 96%;
        margin: 0 2% 20px;
    }

    .ContentPage-ContentTextWrap {
        width: 100%;
        position: relative;
        background-color: transparent;
    }



    .ContentPage-CategoryLink-Btn {
        height: 80px;
        width: 50px;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50px 50px;
    }

    .ContentPage-CategoryLink-Btn_size_L {
        display: block;
    }

    .ContentPage-CategoryLink-Btn .ContentPage-CategoryLink-Btn_size_S {
        display: none;
    }

     .ContentPage-ContentAdditionalTitle {
         display: none;
     }
}





@media (min-width: 1200px) {


    .ContentPage-CategoryLink {
        display: none;
    }


    .ContentPage-Image {
        width: 320px !important;
        height: 320px !important;
        float: left;
        margin: 10px 30px 30px 0;
    }

    .ContentPage-Image {

        float: left;
        margin: 0px 30px 30px 0;
    }


    .ContentPage-ContentTextWrap {
        border-radius: 0;
        width: auto;
        position: relative;
        background-color: transparent;
        box-shadow: none;
    }



     .ContentPage-ContentAdditionalTitle {
         display: none;
     }

      .ContentPage-type_pressa {
          color: #fff;
      }

      .ContentPage-Title:before {
            fill: #fff;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin: 20px 5px 0px 5px;
      }

      .ContentPage-type_pressa-ru-ua-pdf:before {
          background-image: url(/static/media/magazinesWhite.52ac05ed.svg);
      }

      .ContentPage-type_pro-sport:before {
          background-image: url(/static/media/sport.635e55a2.svg);
      }

      .ContentPage-type_pro-avtomobili:before {
          background-image: url(/static/media/auto.25f0ca0d.svg);
      }

      .ContentPage-type_pro-stil:before {
          background-image: url(/static/media/style.4442bbd9.svg);
      }

      .ContentPage-type_pro-gadzhety:before {
          background-image: url(/static/media/gadgets.13285dfe.svg);
      }

      .ContentPage-type_pro-otdykh:before {
          background-image: url(/static/media/relaxation.d48b4557.svg);
      }

      .ContentPage-type_pro-kosmos:before {
          background-image: url(/static/media/space.f75a298a.svg);
      }

      .ContentPage-type_cookery:before {
          background-image: url(/static/media/food.3f0f78ff.svg);
      }

}

.Footer {
    font-family: "Lato Light", Arial, sans-serif;
    border-top: 20px solid #2D2D2D;
    background-color: #2D2D2D;
    color: #adafaf;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
}


.Footer-OfferLink,
.Footer-OfferLink:hover,
.Footer-OfferLink:active,
.Footer-SubscriptionLink,
.Footer-SubscriptionLink:hover,
.Footer-SubscriptionLink:active {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #fff;
    display: block;
    margin: 10px 20px 15px 0;
}

.Footer p {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
}

.Footer-CategoriesLinkWrap {
    padding: 0 0 20px 0;
    font-family: "Lato Light", Arial, sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
}

.Footer-Link {
    color: #7A7A7A;
    line-height: 40px;
    height: 40px;
    text-decoration: none;
    border: 1px solid #7A7A7A;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    padding: 0 5px 0 5px;
    margin: 2px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}


.Footer-Link:hover {
    border: 1px solid #652D86;
    color: #652D86;
}

.Footer .TextLink,
.Footer .TextLink:hover,
.Footer .TextLink:active,
.Footer-Text a,
.Footer-Text a:hover,
.Footer-Text a:active {
    display: inline;
    color: #7A7A7A;
    -webkit-text-decoration: revert;
            text-decoration: revert;
}

.Footer-Text {
    color: #7A7A7A;
    text-align: justify;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

.Footer-LogoContainer {
    display: flex;
    justify-content: flex-start;
    margin-left: 80px;
}

.Footer-LogoContainer span {
    color: #7A7A7A;
}

.Footer-LogoLink,
.Footer-LogoLink:hover,
.Footer-LogoLink:active {
    height: 18px;
    margin-right: 10px;
    -webkit-filter: opacity(0.3);
            filter: opacity(0.3);
}



.Footer-Logo {
    height: 18px;
}

.Footer .HomeLink {
    text-decoration: none;
}

.Footer-Wrapper {
    display: flex;
    width: 100%;
    max-width: 1400px;
    
}

.Footer-OfferLinks {
    display: flex;
    flex-direction: column;
}

.Footer-RightBlock,
.Footer-LeftBlock {
    flex: 1 1;
    margin-bottom: 10px;
}


@media (max-width: 1500px) {
    .Footer-Wrapper {
        padding: 0 20px;
    }
}

@media (max-width: 800px) {
    .Footer-Wrapper {
        flex-direction: column;

    }
}


@media (min-width: 470px) {
    .Footer-CategoriesLinkWrap {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }

    .Footer-CategoryLink {
        margin-right: 5px;
        margin-left: 5px;
    }

}

@media (max-width: 600px) {
    .Footer-Wrapper {
        width: 280px;
        padding: 0;

    }
}


@media (min-width: 720px) {

    .Footer-OfferLinks {
        justify-content: space-between;
    }

    .Footer-Text {
        margin-top: 10px;
    }

    .Footer-LogoContainer {
        justify-content: center;
        margin: 0;
    }

}

@media (min-width: 1200px) {
    
}


@media (min-width: 1200px) {

    .Footer-OfferLinks {
        display: flex;
        justify-content: flex-start;
    }

    .Footer-Text {
        margin-top: auto;
    }

    .Footer-LogoContainer {
        margin-left: 80px;
        justify-content: start;
    }
}


.MusicItem {
    background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#F7F7F7));
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #F7F7F7 100%);
    background: linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%);
}

.MusicItem-Wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px 30px;
}

.MusicItem-Title {
    position: relative;
    font-family: 'Roboto Slab', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    color: #000;
    margin: 26px 20px 0 26px;
}

/*.MusicItem-Title:before {*/
    /*fill: #fff;*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    /*left: -30px;*/
    /*content: '';*/
    /*width: 25px;*/
    /*height: 25px;*/
    /*background-image: url('../Icons/music.svg');*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: contain;*/
/*}*/

.MusicItem .swiper-slide {
    padding-top: 20px;
}

.MusicItem .swiper-scrollbar {
    background: rgba(0, 0, 0, 0.1);
}

.MusicItem .swiper-scrollbar-drag {
    background: #fff000;
    border-radius: 2px;
}

@media (min-width: 600px) {
    .MusicItem-Wrapper {
        padding: 0 20px 36px;
    }
    .MusicItem-ContentWrapper {
        display: grid;
        flex-direction: column;
        justify-content: center;
        grid-gap: 40px;
        width: 100%;
        grid-template-columns: repeat(2, minmax(200px, 348px));
    }
    .MusicItem-Title {
        margin-bottom: 20px;
    }
}

@media (min-width: 1200px) {
    .MusicItem-Wrapper {
        padding: 0 20px 40px;
    }

    .MusicItem-ContentWrapper {
        grid-template-columns: repeat(4, minmax(200px, 320px));
    }
}

.CategoryList {

}


.MainPage {

}

.MainPage-Content {
    margin-top: 93px;
}

@media (max-width: 1200px) {
    .MainPage-Content {
        margin-top: 70px;
    }
}
.OfferPage {
    flex-grow: 1;
}

.OfferPage-Text a{
    color: inherit;
    display: inline;
}

.OfferPage-Text {
    flex-grow: 1;
    max-width: 1300px;
    margin: 93px auto 0;
    text-align: justify;
    padding: 10px;
    font-family: "Lato Light", Arial, sans-serif;
}

.OfferPage-Text h2 {
    font-family: "Lato Heavy", Arial, sans-serif;
    text-align: left;
}

.OfferPage-Text li {
    margin-bottom: 10px;
}

.OfferPage-Text div {
    margin-left: 20px;
    margin-bottom: 10px;
}

@media (min-width: 720px) {
    .OfferPage-Text {
    }
}

@media (max-width: 1200px) {
    .OfferPage-Text {
        margin-top: 70px;
    }
}

.Player {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 48px;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    padding: 0 5px 0 20px;
    font-family: Roboto, sans-serif;
    z-index: 5;
    overflow: hidden;
}

.Player-Inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
}

.Player-Preview {
    height: 28px;
    min-width: 28px;
    margin-right: 10px;
    border-radius: 4px;
}

.Player-PrevTrack, .Player-NextTrack, .Player-Play {
    border: none;
    background-color: inherit;
    outline: none;
}

.Player-PrevTrack:focus, .Player-NextTrack:focus, .Player-Play:focus {
    outline: none;
}

.Player-PrevTrack {
    width: 12px;
    height: 10px;
    background-image: url(/static/media/prevTrack.782d6b22.svg);
    background-repeat: no-repeat;
    background-position: center center;
}

.Player-NextTrack {
    width: 12px;
    height: 10px;
    background-image: url(/static/media/nextTrack.221b3c5b.svg);
    background-repeat: no-repeat;
    background-position: center center;
}

.Player-Play {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Player-Controls {
    min-width: 75px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px 0 5px;
}

.Player-TrackInfoInner {
    padding: 10px;
    height: 100%;
    overflow: hidden;
    position: relative;
    flex: 1 1;
}

.Player-TrackInfoInner:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 15%;
    background: -webkit-gradient(linear, right top, left top, color-stop(60%, rgba(255, 255, 255, 1)), color-stop(70%, rgba(255, 255, 255, 0.8)), color-stop(80%, rgba(255, 255, 255, 0.6)), color-stop(90%, rgba(255, 255, 255, 0.2)), to(rgba(255, 255, 255, 0.1)));
    background: -webkit-linear-gradient(right, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.6) 80%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0.1) 100%);
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.6) 80%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0.1) 100%);
    opacity: 0.9;
}

.Player-TrackName {
    font-size: 14px;
    margin-bottom: 5px;
    width: 100%;
}

.Player-PlayerName {
    font-size: 12px;
    color: #717171;
    width: 100%;
}

.Player-TrackNameInner, .Player-PlayerNameInner {
    display: inline-block;
    white-space: nowrap;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.Player-TrackNameInner.Player-TrackNameInner_isTicker,
.Player-PlayerNameInner.Player-PlayerNameInner_isTicker{
    -webkit-animation: tickerPlayer 5s infinite ease-in-out;
            animation: tickerPlayer 5s infinite ease-in-out;
}

@-webkit-keyframes equalizerPlay1 {
    0% {
        height: 8px;
    }
    25% {
        height: 12px;
    }
    50% {
        height: 16px;
    }
    75% {
        height: 12px;
    }
    100% {
        height: 8px;
    }
}

@keyframes equalizerPlay1 {
    0% {
        height: 8px;
    }
    25% {
        height: 12px;
    }
    50% {
        height: 16px;
    }
    75% {
        height: 12px;
    }
    100% {
        height: 8px;
    }
}

@-webkit-keyframes equalizerPlay2 {
    0% {
        height: 16px;
    }
    25% {
        height: 12px;
    }
    50% {
        height: 8px;
    }
    75% {
        height: 12px;
    }
    100% {
        height: 16px;
    }
}

@keyframes equalizerPlay2 {
    0% {
        height: 16px;
    }
    25% {
        height: 12px;
    }
    50% {
        height: 8px;
    }
    75% {
        height: 12px;
    }
    100% {
        height: 16px;
    }
}

@-webkit-keyframes tickerPlayer {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-120%, 0);
                transform: translate(-120%, 0);
    }
}

@keyframes tickerPlayer {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-120%, 0);
                transform: translate(-120%, 0);
    }
}

.PressContentPage {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #29313E;
    position: relative;
}

.PressContentPage-Header{
    display: none;
}

.PressContentPage .preloader {
    height: 69vh;
}

@media(min-height: 800px) {
    .PressContentPage .preloader {
        height: 59vh;
    }
}

.PressContentPage-ContentWrapper {
    height: calc(100vh - 142px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.PressContentPage * {
    box-sizing: border-box;
}

.PressContentPage-Item {
    background-color: #29313E;
    flex-grow: 1;
    display: flex;
    max-height: 70px;
    min-height: 40px;
}

.PressContentPage-ItemTitleWrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px 10px 60px;
    color: #fff;
    font-size: 20px;
    position: relative;
    font-family: "Roboto Slab Regular";
    margin-bottom: 10px;
}

.PressContentPage-ItemDate {
    position: relative;
    white-space: nowrap;
    margin-left: 10px;
}

.PressContentPage-ItemTitle {
    font-weight: 600;
}

.PressContentPage-ItemDate_hideSymbol:after {
    display: block;
    content: '';
    height: 26px;
    width: 12px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: #29313E;
}

.PressContentPage-CategoryLink,
.PressContentPage-CategoryLink:hover,
.PressContentPage-CategoryLink:active {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-image: url(/static/media/back.12097767.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
}

.PressContentPage-CategoryLink{
    left: 0;
}

.PressContentPage-SwiperWrapper {
    position: relative;
    margin-bottom: auto;
}

.PressContentPage-Paginator {
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    font-weight: normal;
    font-size: 20px;
    margin: 0;
    height: auto;
    padding: 10px 20px 10px 5px;
    line-height: 30px;
    background: rgba(0, 0, 0, 0.4);
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

}

.PressContentPage-CurrentPageInput {
    height: 30px;
    color: #fff;
    background-color: transparent;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    font-size: inherit;
    font-family: inherit;
    outline: none;
    cursor: pointer;
}

.PressContentPage-PaginatorText {
    margin: 0 8px;
    opacity: 1;
}

.PressContentPage .slide {
    width: auto;
    height: calc(100% - 50px);
    background-color: #29313E;
    min-height: calc(100% - 50px);
}

.PressContentPage .swiper-button-next,
.PressContentPage .swiper-button-prev {
    display: none;
    top: 50%;
    background-color: #fff;
    background-size: 40%;
}

.PressContentPage .swiper-button-next {
    right: 250px;
}

.PressContentPage .swiper-button-prev {
    left: 250px;
}


.PressContentPage .swiper-container,
.PressContentPage .swiper-wrapper {
    min-height: 100%;
    background-color: #29313E;
}

.PressContentPage-Image {
    display: block;
    max-width: 100vw;
    margin: 0 auto;
    width: auto;
    height: auto;
}

@media(min-width: 720px) {
    .PressContentPage {
        position: static;
    }

    .PressContentPage-Item {
        width: 720px;
        margin: 0 auto;
    }

    .PressContentPage-SwiperWrapper {
        position: static;
    }

    .PressContentPage-Image {
        max-width: 720px !important;
        height: auto;
        width: 720px;
    }

    .PressContentPage-ContentWrapper {
        margin-top: 10px;
    }
    
}

@media(min-width: 1200px) {

    .PressContentPage-ItemTitleWrapper{
        justify-content: center;
    }

    .PressContentPage-CategoryLink {
        left: -30%;
    }

    .PressContentPage .swiper-button-next,
    .PressContentPage .swiper-button-prev {
        display: block;
    }
}
.SubscriptionPage {
    padding: 10px;
    font-family: "Lato Light", Arial, sans-serif;
    flex-grow: 1;
    display: flex;
}

.SubscriptionPage-Text {
    flex-grow: 1;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 93px;
}

.SubscriptionPage-Text a,
.SubscriptionPage-Text a:hover,
.SubscriptionPage-Text a:active {
    display: inline;
    color: inherit;
}

@media (max-width: 1200px) {
    .SubscriptionPage-Text {
        margin-top: 70px;
    }
}
.App {
    min-width: 320px;
    /*max-width: 1320px;*/

    /*!*margin: 0 auto;*!*/
    /*min-height: 100vh;*/
}

.App-Wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@media (min-width: 720px) {
    .App {
        /*font-family: 'Arial Narrow', Arial, sans-serif;*/
    }
}
@font-face {
  font-family: "Lato Heavy";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Heavy"), url(/static/media/Lato-Heavy.5baa1f3b.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato Light";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), url(/static/media/Lato-Light.94e07077.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), url(/static/media/Lato-Regular.ca14b588.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato Semibold";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Semibold"), url(/static/media/Lato-Semibold.b646f1ff.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: 400;
  src: local("Arial Narrow"), url(/static/media/Arial-Narrow.482a5383.woff2) format("woff2");
  font-display: swap;
}


@font-face {
  font-family: "Arial Narrow Bold";
  font-style: normal;
  font-weight: 400;
  src: local("Arial Narrow Bold"), url(/static/media/Arial-Narrow-Bold.04631ab3.woff2) format("woff2");
  font-display: swap;
}


@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto-Regular"), url(/static/media/Roboto-Regular.ddd18f6d.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  src: local("Roboto Bold"), url(/static/media/Roboto-Bold.9ece5b48.ttf) format("ttf");
  font-display: swap;
}

@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Slab Regular"), url(/static/media/RobotoSlab-Regular.ddd18f6d.woff2) format("woff2");
  font-display: swap;
}

@font-face {

  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Slab"), url(/static/media/RobotoSlab-Bold.1cc55d89.woff2) format("woff2");
  font-display: swap;
}


* {
  -webkit-tap-highlight-color: transparent !important;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  padding: 0;
  font-family: "Lato Regular", Arial, sans-serif;
  line-height: 1;
  overflow-x: hidden;
  min-width: 320px;
  color: #4c4c4e;
  margin: 0 auto;
  position: relative;
}

button,
a {
  cursor: pointer;
  display: block;
}

a {
  text-decoration: none;
}


/*swiper*/
.swiper-button-next,
.swiper-button-prev {
  background-image: url(/static/media/NextArrow.c5406801.svg);
  width: 60px;
  height: 60px;
  z-index: 999;
  background-position: center;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  top: 120px;
}

.swiper-scrollbar {
  height: 4px !important;
  max-width: 1400px !important;
  position: relative !important;
  left: 0 !important;
  background:rgba(255, 255, 255, 0.2);
  margin-top: 30px;
}

.swiper-button-prev {
  left: 80px;
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1);
}
.swiper-button-next{
  right: 80px;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

@media (min-width: 720px) {
  body {
    font-family: "Arial Narrow", Arial, sans-serif;
  }
}



