.MusicItem {
    background: linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%);
}

.MusicItem-Wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px 30px;
}

.MusicItem-Title {
    position: relative;
    font-family: 'Roboto Slab', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    color: #000;
    margin: 26px 20px 0 26px;
}

/*.MusicItem-Title:before {*/
    /*fill: #fff;*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    /*left: -30px;*/
    /*content: '';*/
    /*width: 25px;*/
    /*height: 25px;*/
    /*background-image: url('../Icons/music.svg');*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: contain;*/
/*}*/

.MusicItem .swiper-slide {
    padding-top: 20px;
}

.MusicItem .swiper-scrollbar {
    background: rgba(0, 0, 0, 0.1);
}

.MusicItem .swiper-scrollbar-drag {
    background: #fff000;
    border-radius: 2px;
}

@media (min-width: 600px) {
    .MusicItem-Wrapper {
        padding: 0 20px 36px;
    }
    .MusicItem-ContentWrapper {
        display: grid;
        flex-direction: column;
        justify-content: center;
        grid-gap: 40px;
        width: 100%;
        grid-template-columns: repeat(2, minmax(200px, 348px));
    }
    .MusicItem-Title {
        margin-bottom: 20px;
    }
}

@media (min-width: 1200px) {
    .MusicItem-Wrapper {
        padding: 0 20px 40px;
    }

    .MusicItem-ContentWrapper {
        grid-template-columns: repeat(4, minmax(200px, 320px));
    }
}
