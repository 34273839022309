.MusicItemPreview {
    height: 360px;
    min-width: 270px;
    max-width: 280px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
    padding: 20px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: visible;
    font-family: 'Roboto', sans-serif;
    background: #fff;
}

.MusicItemPreview-ImageWrapper {
    height: 240px;
    width: 100%;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
}

.MusicItemPreview-Image {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.MusicItemPreview-Progress {
    position: absolute;
    top: 0;
    background: #fff000;
    width: 130px;
    height: 2px;
}

.MusicItemPreview-TrackInfo {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.MusicItemPreview-TrackInfoWrapper {
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
    border-radius: 0 0 10px 10px;
    width: 100%;
    height: 60px;
}

.MusicItemPreview-TrackInfoInner {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Roboto, sans-serif;
    overflow: hidden;
    position: relative;
    padding-left: 20px;
}

.MusicItemPreview-TrackInfoInner:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.8) 40%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0.4) 80%, rgba(255, 255, 255, 0.1) 100%);
    z-index: 1;
    opacity: 0.8;
}

/*.MusicItemPreview-TrackInfoInner:before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    width: 20px;*/
/*    background: linear-gradient(to right, rgba(255, 255, 255, 0.9) 10%, rgba(255, 255, 255, 0.8) 20%, rgba(255, 255, 255, 0.5) 40%, rgba(255, 255, 255, 0.4) 60%, rgba(255, 255, 255, 0.3) 70%, rgba(255, 255, 255, 0.2) 80%, rgba(255, 255, 255, 0.1) 100%);*/
/*    z-index: 1;*/
/*    opacity: 0.9;*/
/*}*/


.MusicItemPreview-PlayerState {
    min-width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background: rgba(255, 255, 255, 0.8);*/
}

.MusicItemPreview-TrackName {
    font-size: 14px;
    margin-bottom: 5px;
    width: 100%;
}

.MusicItemPreview-TrackNameInner,
.MusicItemPreview-PlayerNameInner,
.MusicItemPreview-NextTrackNameInner,
.MusicItemPreview-NextPlayerNameInner
{
    display: inline-block;
    white-space: nowrap;
    transition: 0.5s;
}

.MusicItemPreview-TrackNameInner.MusicItemPreview-TrackNameInner_isTicker,
.MusicItemPreview-PlayerNameInner.MusicItemPreview-PlayerNameInner_isTicker,
.MusicItemPreview-NextPlayerNameInner.MusicItemPreview-NextPlayerNameInner_isTicker,
.MusicItemPreview-NextTrackNameInner.MusicItemPreview-NextTrackNameInner_isTicker {
    animation: ticker 5s infinite ease-in-out;
}

.MusicItemPreview-PlayerName {
    color: #414141;
    font-size: 12px;
    width: 100%;
}

.MusicItemPreview-NextTrackWrapper {
    margin-top: 17px;
    cursor: pointer;
    min-height: 60px;
}

.MusicItemPreview-NextTrackTitle {
    font-size: 12px;
    line-height: 1;
    color: #8D8D8D;
    margin: 0;
    font-family: 'Roboto Slab', sans-serif;
}

.MusicItemPreview-NextTrackInfo {
    padding-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    flex: 1;
}

.MusicItemPreview-NextTrackInfoInner:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.6) 80%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0.1) 100%);
    opacity: 0.9;
}

.MusicItemPreview-NextTrackInfoWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.MusicItemPreview-NextTrackInfoInner {
    display: flex;
    padding-right: 5px;
    width: 100%;
}

.MusicItemPreview-NextTrackInfoInner.MusicItemPreview-NextTrackInfoInner_hidden {
    display: none;
}

.MusicItemPreview-NextTrackInfoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    flex: 1;
    overflow: hidden;
    width: 100%;
}

.MusicItemPreview-Arrow {
    z-index: 1;
    position: absolute;
    right: 0;
}

.MusicItemPreview-NextTrackPreviewWrapper {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background-color: grey;
    position: relative;
}

.MusicItemPreview-NextTrackPreviewWrapper::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    background-image: url('../Icons/nextTrackPlay.svg');
    background-position: 50% 50%;
    background-size: cover;
    height: 19px;
    width: 19px;
}

.MusicItemPreview-NextTrackName {
    font-size: 12px;
    margin-bottom: 3px;
}

.MusicItemPreview-NextPlayerName {
    font-size: 10px;
    color: #717171;
}

@keyframes ticker {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-120%, 0);
    }
}

@media (min-width: 601px) {
    .MusicItemPreview {
        max-width: unset;
        width: 100%;
        height: 432px;
    }
    .MusicItemPreview-ImageWrapper {
        height: 304px;
    }
}

@media (min-width: 1200px) {
    .MusicItemPreview {
        height: 400px;
    }
    .MusicItemPreview-ImageWrapper {
        height: 280px;
    }
}
