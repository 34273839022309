.MainPage {

}

.MainPage-Content {
    margin-top: 93px;
}

@media (max-width: 1200px) {
    .MainPage-Content {
        margin-top: 70px;
    }
}