.preloader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 50px;
    z-index: 10000;
    box-sizing: border-box;
}

.preloader--slider-item {
    padding-top: 43vh;
}

.preloader--fullscreen {
    position: fixed;
    padding: 0;
    background-color: #ffffff;
}

.preloader--vertical {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
