.SubscriptionPage {
    padding: 10px;
    font-family: "Lato Light", Arial, sans-serif;
    flex-grow: 1;
    display: flex;
}

.SubscriptionPage-Text {
    flex-grow: 1;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 93px;
}

.SubscriptionPage-Text a,
.SubscriptionPage-Text a:hover,
.SubscriptionPage-Text a:active {
    display: inline;
    color: inherit;
}

@media (max-width: 1200px) {
    .SubscriptionPage-Text {
        margin-top: 70px;
    }
}