.ContentDescription {
    position: relative;
    font-family: Roboto, sans-serif;
    color: #333333;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 85%;
    max-width: 280px;
    margin: auto;
    margin-top: 10px;
}

.ContentDescription_size_small {
    color: #58595b;
    margin: 0;
    margin-top: -2px;
}

.ContentDescription-Title {
    background-color: #fff;
    color: #8A8D8F;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
    position: relative;
    font-family: "Roboto", sans-serif;
}

.ContentDescription-Tags,
.ContentDescription-Type {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 700;
    height: 20px;
    margin-left: 0;
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
    color: #000;
}

.ContentDescription-TitleText {
    font-family: 'Roboto', sans-serif;
}

.ContentDescription-Type {
    display: none;
}

/*стили для контента на странице контента*/
.ContentDescription_ofPage_ContentPage.ContentDescription_size_common .ContentDescription-Title{
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
}


@media (max-width: 600px) {
    .ContentDescription {
        width: 240px;
    }
}