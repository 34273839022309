.Footer {
    font-family: "Lato Light", Arial, sans-serif;
    border-top: 20px solid #2D2D2D;
    background-color: #2D2D2D;
    color: #adafaf;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
}


.Footer-OfferLink,
.Footer-OfferLink:hover,
.Footer-OfferLink:active,
.Footer-SubscriptionLink,
.Footer-SubscriptionLink:hover,
.Footer-SubscriptionLink:active {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #fff;
    display: block;
    margin: 10px 20px 15px 0;
}

.Footer p {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
}

.Footer-CategoriesLinkWrap {
    padding: 0 0 20px 0;
    font-family: "Lato Light", Arial, sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
}

.Footer-Link {
    color: #7A7A7A;
    line-height: 40px;
    height: 40px;
    text-decoration: none;
    border: 1px solid #7A7A7A;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    padding: 0 5px 0 5px;
    margin: 2px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}


.Footer-Link:hover {
    border: 1px solid #652D86;
    color: #652D86;
}

.Footer .TextLink,
.Footer .TextLink:hover,
.Footer .TextLink:active,
.Footer-Text a,
.Footer-Text a:hover,
.Footer-Text a:active {
    display: inline;
    color: #7A7A7A;
    text-decoration: revert;
}

.Footer-Text {
    color: #7A7A7A;
    text-align: justify;
    hyphens: auto;
}

.Footer-LogoContainer {
    display: flex;
    justify-content: flex-start;
    margin-left: 80px;
}

.Footer-LogoContainer span {
    color: #7A7A7A;
}

.Footer-LogoLink,
.Footer-LogoLink:hover,
.Footer-LogoLink:active {
    height: 18px;
    margin-right: 10px;
    filter: opacity(0.3);
}



.Footer-Logo {
    height: 18px;
}

.Footer .HomeLink {
    text-decoration: none;
}

.Footer-Wrapper {
    display: flex;
    width: 100%;
    max-width: 1400px;
    
}

.Footer-OfferLinks {
    display: flex;
    flex-direction: column;
}

.Footer-RightBlock,
.Footer-LeftBlock {
    flex: 1;
    margin-bottom: 10px;
}


@media (max-width: 1500px) {
    .Footer-Wrapper {
        padding: 0 20px;
    }
}

@media (max-width: 800px) {
    .Footer-Wrapper {
        flex-direction: column;

    }
}


@media (min-width: 470px) {
    .Footer-CategoriesLinkWrap {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }

    .Footer-CategoryLink {
        margin-right: 5px;
        margin-left: 5px;
    }

}

@media (max-width: 600px) {
    .Footer-Wrapper {
        width: 280px;
        padding: 0;

    }
}


@media (min-width: 720px) {

    .Footer-OfferLinks {
        justify-content: space-between;
    }

    .Footer-Text {
        margin-top: 10px;
    }

    .Footer-LogoContainer {
        justify-content: center;
        margin: 0;
    }

}

@media (min-width: 1200px) {
    
}


@media (min-width: 1200px) {

    .Footer-OfferLinks {
        display: flex;
        justify-content: flex-start;
    }

    .Footer-Text {
        margin-top: auto;
    }

    .Footer-LogoContainer {
        margin-left: 80px;
        justify-content: start;
    }
}

