.ErrorPage {
    text-align: center;
    height: 85vh;
    margin-bottom: 1px;
}

.ErrorPage-LogoContainer {
    background-color: #fff;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    align-items: center;
}

.ErrorPage-LogoContainer {
    padding-left: 20px;
}

.ErrorPage-Logo {
    max-height: 40px;
}


.ErrorPage-Title {
    margin: 30vh 0 20px;
    text-align: center;
}

.ErrorPage-Title span {
    display: block;
}

.ErrorPage-Link,
.ErrorPage-Link:hover,
.ErrorPage-Link_active {
    display: block;
    text-decoration: none;
    width: 170px;
    font-size: 24px;
    line-height: 50px;
    background-color: #f5d300;
    background-image: none;
    border-radius: 25px;
    text-align: center;
    margin: 0 auto 100px;
    color: #4c4c4e;
}

@media (min-width: 720px) {
    .ErrorPage {
        font-family: "Arial Narrow", Arial, sans-serif;
    }

    .ErrorPage * {
        font-family: inherit;
    }

}
