.PressContentSlider-ContentItem {

}

.PressContentSlider .swiper-button-prev,
.PressContentSlider .swiper-button-next {
    display: none;
    background-color: #fff;
    background-size: 50%;
    transform-style: preserve-3d;
    z-index: 100000000;
}
.PressContentSlider .swiper-button-next.swiper-button-disabled,
.PressContentSlider .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
}

.PressContentSlider .swiper-button-prev::before,
.PressContentSlider .swiper-button-next::before {
    display: block;
    content: '';
    width: 500px;
    height: 350px;
    background: linear-gradient(90deg, #3B454E 0%, rgba(59, 69, 78, 0) 70%);
    transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(-1px);
    pointer-events: none;
    position: absolute;
    bottom: -130px;
    right: -100px;
}

.PressContentSlider-blackout-next {
     transform: matrix(1, 0, 0, 1, 0, 0);
     right: 20px;
}

.swiper-scrollbar-drag {
    height: 4px;
    background: #ADAFAF;
}

.swiper-scrollbar {
    margin: auto;
}

/*.swiper-slide {*/
/*    width: auto !important;*/
/*    margin-right: 10px !important;*/
/*}*/

.PressContentPage .swiper-slide {
    width: 100% !important;
    margin-right: 30px !important;
}

@media (min-width: 1200px) {
    .swiper-button-prev::before,
    .swiper-button-next::before {
        display: block;
    }

    .PressContentSlider .swiper-button-prev,
    .PressContentSlider .swiper-button-next {
        display: block;
        transform-style: preserve-3d;

    }
    .CategoryPage-Content .swiper-button-prev {
        background: transparent;
    }
    .CategoryPage-Content .swiper-button-prev::before,
    .CategoryPage-Content .swiper-button-next::before {
        height: 320px;
        bottom: -130px;
        background: linear-gradient(90deg, #3B454E 0%, rgba(59, 69, 78, 0) 100%);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(-1px);

    }

}
