.Header {
    background-color: #fff;
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 93px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Header-Wrapper {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    max-width: 1440px;
}

.Header-Logo {
    max-height: 25px;
}

.Header-LogoContainer {
    margin-right: 20px;
}

.Header-ButtonWrapper {
    position: relative;
    height: 100%;
    width: 50px;
    min-width: 50px;
}

.Header-Select {
    cursor: pointer;
    position: relative;
    margin-left: auto;
    width: 50px;
}

.Header-SelectList {
   display: none;
    padding: 0;
    list-style-type: none;
}

.Header-SelectList_visible {
    position: absolute;
    top: 100%;
    display: block;
    margin: 0;
    background-color: #fff;
    width: 40px;
}

.Header-SelectItem {
    font-family: "Roboto Slab Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 30px 10px 0;
    width: 60px;
}

.Header-SelectItem span {
    font-size: 16px;
}

.Header-SelectItem_state_selected {
    background-image: url("../Icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 30px 13px;
    background-size: 10px;
}

.Header-SelectItem_active {
    display: none;
}


.Header-HamburgerButton,
.Header-CloseButton {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0;
    transition:  all cubic-bezier(0.17,0.67,1,-0.1) 0.5s;
}

.Header-HamburgerButton_visible,
.Header-CloseButton_visible {
    opacity: 1;
}

.Header-Menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    height: 100vh;
    min-height: 100%;
    z-index: 1;
    transform: translateX(-100%);
    opacity: 0;
    transition: opacity ease-in-out 0.8s, transform ease-in-out 0.2s;
}

.Header-Menu_visible {
    transform: translateX(0);
    opacity: 1;
    height: 200vh;
    width: 100%;
}



@media (max-width: 1200px) {
    .Header-Wrapper {
        width: 100%;
        padding: 10px 0;
    }
    .Header {
        height: 70px;
    }
}





@media (min-width: 1200px) {



    .Header-Menu,
    .Header-Menu_visible {
        display: flex;
        justify-content: center;
        position: relative;
        transform: translateX(0);
        height: auto;
        top: auto;
        bottom: auto;
        left: 0px;
        opacity: 1;
    }

    .Header-ButtonWrapper {
        display: none;
    }

    .Header-LogoContainer {
        margin-left: 20px;
    }
}

.Header-PersonalCabinet {
    width: 100%;
    max-width: 1440px;
    font-size: 12px;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px #4c4c4e solid;
}

.Header-PersonalCabinet a {
    color: #4c4c4e;
    text-decoration: underline;
}
