

.PressContentList-Item {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 20px 10px 0 rgb(0 0 0 / 20%);
    margin-bottom: 10px;
}

.PressContentList-Wrapper {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.PressContentList-ItemTitle {
    
    max-width: calc(100% - 40px);
    display: inline-block;
    font-size: 26px;
    line-height: 1;
    padding: 0;
    margin: 0;
    text-align: start;
    font-family: "Lato Light", Arial, sans-serif;
    /*max-width: 745px;*/
    color: #fff;
    margin: 15px 20px;
}

.PressContentList-ItemTitle span {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 56px;
    padding-bottom: 10px;
}

@media (max-width: 600px) {}


@media (max-width: 800px) {
    
    .PressContentList-Wrapper {
        width: 100%;
    }
}

