.ContentAdditional {
    display: flex;
    flex-direction: column;
    margin: auto;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(8, auto);    
    margin-bottom: 30px;
}




@media (max-width: 800px) {

    .ContentAdditional {
        color: #fff;
        grid-template-columns: repeat(2, minmax(240px, 348px));
        grid-template-rows: repeat(2, auto);
        grid-auto-flow: dense;
    }

}

@media (max-width: 600px) {

    .ContentAdditional {
        grid-template-columns: repeat(1, auto);
        grid-template-rows: repeat(4, auto);
    }

}